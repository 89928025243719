<template>
  <el-card class="iot-person iot-search-header public-page-style">
    <div slot="header" class="clearfix">
      <el-form :inline="true" :model="getForm">
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.roleName"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="checkTable()"
            >查询</el-button
          >
        </el-form-item>
        <el-button
          type="primary"
          plain
          size="mini"
          class="iot-role-create"
          @click="addRole()"
          v-show="$store.state.permissionArr.indexOf('role:add') > -1"
          >新增</el-button
        >
      </el-form>
    </div>

    <el-table
      :data="tableData"
      border=""
      stripe
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column prop="roleId" label="角色编号"></el-table-column>
      <el-table-column prop="roleName" label="角色名称"></el-table-column>
      <el-table-column prop="rank" label="角色级别"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            v-show="$store.state.permissionArr.indexOf('role:update') > -1"
            @click="editRole(scope.row.roleId)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="primary"
            plain
            v-show="
              $store.state.permissionArr.indexOf('permission:update') > -1
            "
            @click="settingRole(scope.row.roleId, scope.row.roleName)"
            >配置权限</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            v-show="$store.state.permissionArr.indexOf('role:delete') > -1"
            @click="del(scope.row.roleId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
     
    </el-table>
     <el-pagination
     @current-change="currentchange"
        :hide-on-single-page="true"
        :total="total"
        :page-size="getForm.size"
        layout="prev, pager, next"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getList();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getList();
          }
        "
      >
      </el-pagination>
    <el-dialog
    top="3%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="roleDialogVisinbile"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="roleForm"
        ref="roleRef"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="roleForm.roleName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色级别" prop="rank">
          <el-input v-model="roleForm.rank" autocomplete="off"></el-input>
        </el-form-item>
        <el-input
          v-model="roleForm.roleId"
          type="hidden"
          size="mini"
          autocomplete="off"
        ></el-input>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" plain size="mini" @click="submitForm"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="roleDialogVisinbile = false"
          >取 消</el-button
        >
       
      </div>
    </el-dialog>
    <el-dialog
    top="3%"
      class="qxpz"
      title="配置权限"
      :show-close="false"
      :visible.sync="distributionDialogVisinbile"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <h4>{{ `角色：${settingData.roleName}` }}</h4>
      <h4>分配权限</h4>
      <el-form
        :inline="true"
        :model="settingData"
        label-width="70px"
        class="iot-role-permission"
        ref="permissionRef"
      >
        <el-form-item label="">
          <el-tree
            :data="menu"
            :props="defaultProps"
            show-checkbox
            node-key="menuId"
            ref="roleTree"
            :check-strictly="true"
            :default-checked-keys="settingData.menuIds"
            @check-change="checkChange"
          ></el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" plain size="mini" @click="subSetting()"
          >确 定</el-button
        >
        <el-button type="info" plain size="mini" @click="permissionClose"
          >取 消</el-button
        >
       
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
let that;
export default {
  name: "Role",
  data() {
    return {
      menu:[],
      theme: { TABLE_HEADER_COLOR },
      roleDialogVisinbile: false,
      tableData: [{}],
      getForm: {
        roleName: "",
        size: 10,
        current: 1,
        rank:"",
      },
      total: 0,
      rules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        rank: [
          { required: true, message: "请输入角色级别", trigger: "blur" },
        ],
      },
      roleForm: {
        roleName: "",
        roleId: "",
      },
      isCreate: true,
      formTitle: "",
      settingData: {
        roleId: "",
        roleName: "",
        menuIds: [],
      },
      settingDialogVisibile: false,
      distributionDialogVisinbile: false,
      defaultProps: {
        label: "menuName",
        children: "children",
      },
    };
  },
  methods: {
    getMenuNoPage() {
      that.$api.menuManagement.getMenu().then((response) => {
        that.menu = response.data;
      });
    },
    submitForm() {
      that.$refs["roleRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.role.createRole(that.roleForm)
            : that.$api.role.editRole(that.roleForm);
          _api.then((response) => {
            if (response.code == 200) {
              that.roleDialogVisinbile = false;
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getList();
            }
          });
        }
      });
    },
    checkTable() {
      that.$set(that.getForm, "current", 1);
      that.getList();
    },
    currentchange(val) {
      that.getForm.current = val 
      that.getList()
    },
    getList() {
      that.$api.role.roleList(that.getForm).then((response) => {
        that.tableData = response.data.records;
        that.total = response.data.total;
      });
    },
    addRole() {
      that.roleDialogVisinbile = true;
      that.isCreate = true;
      that.formTitle = "新增角色";
      that.roleForm = {};
    },
    handleClose() {
      that.settingDialogVisibile = false;
    },
    editRole(roleId) {
      new Promise((resolve) => {
        that.$api.role.roleInfo(roleId).then((response) => {
          if (response.code == 200) {
            resolve(response.data);
          }
        });
      }).then((res) => {
        that.$nextTick(() => {
          that.roleForm = Object.assign({}, that.roleForm, res);
        });
        that.roleDialogVisinbile = true;
        that.isCreate = false;
        that.formTitle = "编辑角色";
      });
    },
    del(roleId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.role.delRole(roleId).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getList();
            }
          });
        })
        .catch(() => {});
    },
    settingRole(roleId, roleName) {
      new Promise((resolve) => {
        that.$api.role.getSingleSettinginfo(roleId).then((response) => {
          if (response.code == 200) {
            resolve(response.data);
          }
        });
      }).then((res) => {
        that.$set(that.settingData, "roleId", roleId);
        that.$set(that.settingData, "roleName", roleName);
        that.$set(that.settingData, "menuIds", res);
        that.distributionDialogVisinbile = true;
      });
    },
    subSetting() {
      new Promise((resolve) => {
        that.$api.role
          .distributionPermission(that.settingData)
          .then((response) => {
            if (response.code == 200) {
              resolve(response.msg);
            }
          });
      }).then((data) => {
        that.$set(that.settingData, "menuIds", "");
        that.distributionDialogVisinbile = false;
        that.getList();
        that.$message({
          type: "success",
          message: data,
        });
      });
    },
    permissionClose() {
      that.$set(that.settingData, "menuIds", "");
      that.distributionDialogVisinbile = false;
    },
    checkChange() {
      that.$set(
        that.settingData,
        "menuIds",
        that.$refs.roleTree.getCheckedKeys()
      );
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.getList();
    that.getMenuNoPage();
  },
};
</script>

<style scoped>
.iot-role-create {
  float: right;
  margin: 10px;
}
.iot-role-permission {
  height: 319px;
  overflow: auto;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.qxpz /deep/ .el-dialog__body {
  padding: 0 20px;
  width: 100%;
}

.qxpz /deep/ .el-dialog {
  width: 35%;
}
</style>
